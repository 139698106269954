import React from 'react';
import Layout from '../components/layout';
import Helmet from 'react-helmet';

import TeamModal from '../components/TeamModal';
import PartnerModal from '../components/PartnerModal';

const Team = () => (<Layout>
    <div className="skipScroll">
      <Helmet
        title={'temtum Team | Richard Dennis'}
        meta={[
          {
            name: 'description',
            content: 'temtum is made of up worldclass cryptographers, supported by senior management, experienced across corporate business & finance.'
          }
        ]}
      />
      <div className="hd-space"/>
      <div className="page skipScroll">
        <section className="bg-blue-3 text-white skipScroll circlebgX  pb-0 bg2">
          <div className="darker blueblue video-overlay"/>
          <div className="wow3 video-overlay"/>

          <div className="container pb-5">
            <div className="row">
              <div className="col-md-7">
                <h1 className="display-2 font-weight-normalX pb-3 mb-4 headline red">
                  Team &amp; Advisors
                </h1>
                <p className="leadX  mb-5">
                  One of the strongest teams in the Blockchain industry, temtum is developed by Dragon Infosec on top of their Temporal Blockchain. Dragon is made up of published cybersecurity and cryptography experts, with a wealth of experience in not only blockchain but corporate business at board level in FTSE 250 companies.
                </p>
              </div>
            </div>
          </div>
          <div className="affixpad"/>
          <div className="skipScroll py-4 border-bottomX teamlinks bg-blue-3">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <ul className="nav nav-pills nav-fill">
                    <li className="nav-item">
                      <a className="nav-link activeX scroll" href="#core">
                        Core Team
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scroll" href="#directors">
                        Board of Directors
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scroll" href="#development">
                        Development Team
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scroll" href="#advisors">
                        Advisory Board
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scroll" href="#partners">
                        Partners
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="team" className="border-topX team team-white text-whiteX skipScroll pt-5">
          <div className="container mt-5" id="core">
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-12 mx-auto" data-aos="fade-up" data-aos-duration="1200">
                <h3 className="display-4 font-weight-bold mb-5">Core Team</h3>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="400">
                  <div className="card-body">
                    <img src={require('../img/team/richard-dennis.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/richard-dennis-a4a3509a">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Richard Dennis</h4>
                    <h5>Founder & CEO</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#richard">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="richard" img={require('../img/team/richard-dennis.jpg')} name="Richard Dennis" title="Founder & CEO" linkedin="richard-dennis-a4a3509a" description="Richard is the founder of Dragon, and a globally acknowledged, prolific and prodigious cybersecurity and cryptography expert. He is also internationally recognised as a one of the world’s leading cybersecurity lecturers with a specialism in secure networks, blockchain and encryption. He has published a significant body of research and presentations on next generation solutions across a wide range of subjects, including Bitcoin wallet vulnerability, an analysis into the scalability of Bitcoin and Ethereum, and a formal analysis of the Temporal block."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="600">
                  <div className="card-body">
                    <img src={require('../img/team/steven-feinberg.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/steven-feinberg-3a58335">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Steven Feinberg</h4>
                    <h5>COO</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#stevenf">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="stevenf" img={require('../img/team/steven-feinberg.jpg')} name="Steven Feinberg" title="CCO" linkedin="steven-feinberg-3a58335" description="Steven serves as the Managing Director of Finstar Capital, a firm focused on high growth opportunities internationally.  He has been an entrepreneur in San Francisco for over 25 years and is experienced in sales, marketing, operations  and community building."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <img src={require('../img/team/michael-terpin.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/michaelterpin">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Michael Terpin</h4>
                    <h5>Lead Blockchain Advisor</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#michaelt">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="michaelt" img={require('../img/team/michael-terpin.jpg')} name="Michael Terpin" title="Lead Blockchain Advisor" linkedin="michaelterpin" description="Michael Terpin is a serial entrepreneur who has founded leading companies in public relations, internet marketing, digital innovation, cryptocurrency, and newswire distribution. He is well known for his current role as the Founder and CEO of Transform PR, the world leader in blockchain and Initial Coin Offering (ICO) PR and advisory services which has represented more than 100 ICOs. Michael is also known for co-founding Marketwired the first Internet-based company newswire, which was acquired for over $35 million in 2006, CoinAgenda, an event series for cryptocurrency investors, and Transform Strategies, the company’s advisory division. Michael is also an investor and advisor to many blockchain, media and technology companies."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <img src={require('../img/team/deacon-john.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="#">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>John Deacon</h4>
                    <h5>Lead Financial Services</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#deaconjohn">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="deaconjohn" img={require('../img/team/deacon-john.jpg')} name="Lead Financial Services" title="Lead Financial Services" linkedin="" description="Founder of the Giltspur group and acknowledged expert in securitisation and principal finance, with experience running principal investment businesses at UBS, Dillon Read Capital Management and Greenwich NatWest. Has worked as an investor, trader, issuer, investment banker and lawyer in London, Paris and Hong Kong, including experience as a leading investor in the European structured finance market and as Chairman of the CMSA-Europe (Commercial Mortgage Securities Association) Investor Forum."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="600">
                  <div className="card-body">
                    <img src={require('../img/team/cyntia-aguirre.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/cintya-aguirre-31b90823">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Cintya Aguirre</h4>
                    <h5>CSA</h5> 
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#cintya">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="cintya" img={require('../img/team/cyntia-aguirre.jpg')} name="Cintya Aguirre" title="CSA" linkedin="cintya-aguirre-31b90823" description="Cintya is a Master of Science in Software Engineering with a strong background in software development and IT architecture. She is an experienced full stack developer who has worked designing and implementing software as an IT architect for the financial industry (Banco del Austro S.A.). She also holds a PMP accreditation (Project Manager Professional) certified by Project Management Institute USA."/>
            </div>
          </div>

          <div className="container mt-5" id="directors">
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-12 mx-auto text-centerX" data-aos="fade-up" data-aos-duration="1200">
                <h3 className="display-4 font-weight-bold mb-5">
                  Board of Directors
                </h3>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="200">
                  <div className="card-body">
                    <img src={require('../img/team/david-shimmon.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/david-shimmon-34997934">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>David Shimmon</h4>
                    <h5>Director</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#david">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="david" img={require('../img/team/david-shimmon.jpg')} name="David Shimmon" title="Director" linkedin="david-shimmon-34997934" description="David Shimmon is a highly experienced CEO of the highest calibre. His last role was as CEO of Ichor Systems, Inc, one of the world’s leading semiconductor businesses.  He was also CEO of global digital technology agency Celerity from 1999 to 2008, executive chairman from 2000 to 2008 and executive director from 2000 to 2008. Prior to this he had a number of other senior roles, including president of Kinetics Group, Inc., a subsidiary of Celerity, Inc. from 1996 to 2005, CFO from 1991 to May 2005, and COO from 1990-1991. He has also been the director of The Tech Museum of Innovation in California."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1400">
                  <div className="card-body">
                    <img src={require('../img/team/campbell-law.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/campbell-law-5235b6128">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Campbell Law</h4>
                    <h5>Director</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#campbell">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="campbell" img={require('../img/team/campbell-law.jpg')} name="Campbell Law" title="Director" linkedin="campbell-law-5235b6128" description="Campbell is the managing director of Beacon Management (Cayman) Ltd and has over 25 years’ experience in the financial services industry. He has considerable experience working with both offshore and local businesses and has specific expertise with local company set up and licensing, fund administration, corporate governance and regulatory risk management. Campbell has sat on various Cayman Islands Government Boards including the Trade & Business Licensing Board and is the former chairman of the Liquor Licensing Board along with review committees for both the Trade & Business Licensing Law and the Local Companies Control License Law. Mr. Law is a Notary Public in the Cayman Islands."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="200">
                  <div className="card-body">
                    <img src={require('../img/team/kanika-green.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/kanika-green-llb-hons-99065a14">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Kanika Green</h4>
                    <h5>Director</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#kanika">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="kanika" img={require('../img/team/kanika-green.jpg')} name="Kanika Green" title="Director" linkedin="kanika-green-llb-hons-99065a14" description="Kanika is the managing director/CEO at Corporate Management Solutions (Cayman) Ltd, a fiduciary boutique. Mrs. Green is an approved director and Anti-Money Laundering Reporting Officer by the Cayman Islands Monetary Authority (CIMA). Mrs. Green has a law degree (LLB Hons.) from University  of Wolverhampton in the UK which she completed in 2007, and was called to the bar in 2014. Mrs. Green’s professional memberships include: Member of the Caymanian Bar Association, Member of the International Bar Association, Member of the 100 Women in Hedge Funds, Notary Public for the Cayman. "/>
            </div>
          </div>

          <div className="container mt-5" id="development">
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-12 mx-auto text-centerX" data-aos="fade-up" data-aos-duration="1200">
                <h3 className="display-4 font-weight-bold mb-5">
                  Development Team
                </h3>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="800">
                  <div className="card-body">
                    <img src={require('../img/team/dev3.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/karpotkin/">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Eugene Zimnitskiy</h4>
                    <h5>Lead Developer</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#eugene">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="eugene" img={require('../img/team/dev3.jpg')} name="Eugene Zimnitskiy" title="Lead Developer" linkedin="karpotkin" description="Senior/Team Lead Full Stack Developer. Stack of technologies: JavaScript, TypeScript, NodeJS, Angular, VueJS, React Native, React, HTML, CSS, Apache Cordova, AWS, MongoDB, LMDB, Blockchain, MySQL, MSSQL, PostgreSQL, PHP, C#, Ruby. He have been developing applications for 8 years. He projects included cross-platform mobile applications, chatbots, desktop, finance and more."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <img src={require('../img/team/dev1.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/egor-veremeychik-105525109/">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Egor Veremeychik</h4>
                    <h5>iOS Developer</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#egor">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="egor" img={require('../img/team/dev1.jpg')} name="Egor Veremeychik" title="iOS Developer" linkedin="egor-veremeychik-105525109" description="Egor is an iOS developer. He has over four years’ experience in software development. His core technology is iOS. Egor is responsible for writing temtum applications, and for developing and supporting the iOS application. His main tasks include the implementation of data, views, logic layers, bug-fixing, performance and security optimizations. Languages: Swift. Technologies and tools used in iOS application: CoreData, Alamofire, DeepLinks, WebSocket, XCTest, Cocoa Pods, Keychain, Firebase Messaging, Fabric, Crashlytics."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1400">
                  <div className="card-body">
                    <img src={require('../img/team/dev6.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/alexey-yalovik-089488182">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Alexey Yalokiv</h4>
                    <h5>Android Developer</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#alexey">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="alexey" img={require('../img/team/dev6.jpg')} name="Alexey Yalokiv" title="Android Developer"  linkedin="alexey-yalovik-089488182" description="Alex is principally an Android developer, with over three and a half years’ experience in software development. His core skill is Android. He is responsible for writing the temtum platform, and he develops and supports the Android application. His tasks include the implementation of new features, UI layout, bug-fixing, performance and security optimisations. Technologies and tools used in Android application: RxJava, Mvp, SafetyNet, FCM, DBFlow, SQLiteCipher, Android KeyStore, Retrofit, OkHttp, Bamboo, Figma, Fabric, WebSocket, Robolectric, JUnit. "/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1400">
                  <div className="card-body">
                    <img src={require('../img/team/dev5.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/vladyko">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Andrey Vladyko</h4>
                    <h5>Android Developer</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#andrey">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="andrey" img={require('../img/team/dev5.jpg')} name="Andrey Vladyko" title="Android Developer" linkedin="vladyko" description="Andrey is an Android developer with eight years experience. His stack of technologies includes: RxJava, Mvp, SafetyNet, FCM, DBFlow, SQLiteCipher, Android KeyStore, Retrofit, OkHttp, Bamboo, Figma, Fabric, WebSocket, Robolectric, JUnit. His tasks included designing the application architecture, UI Layout, bug fixing, performance and security optimisations. "/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <img src={require('../img/team/dev4.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/artyom-koush-08279a143">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Artem Koush</h4>
                    <h5>Web Developer</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#artem">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="artem" img={require('../img/team/dev4.jpg')} name="Artem Koush" title="Web Developer" linkedin="artyom-koush-08279a143" description="Artem is a web developer. His stack of technologies includes: JavaScript, TypeScript, NodeJS, Angular, VueJS, HTML, CSS, jQuery, Apache Cordova, Ionic Framework, MongoDB, MySQL, PostgreSQL. He has been developing web applications for four years. His projects included cross-platform mobile applications, chatbots and more. "/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1200">
                  <div className="card-body">
                    <img src={require('../img/team/dev2.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/illia-zabrodski-b560b1181">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Illia Zabrodsky</h4>
                    <h5>JS Developer</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#illia">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="illia" img={require('../img/team/dev2.jpg')} name="Illia Zabrodsky" title="JS Developer" linkedin="illia-zabrodski-b560b1181" description="Illia is a full-stack JS developer with extensive experience, management and teamwork skills. As a developer with around five years creating awesome software, he has mastered web development and uses a highly scientific approach to coding. He applies the latest technologies and know-how to make the project work. His stack of technologies includes: JavaScript, TypeScript, NodeJS, Angular, VueJS, HTML, CSS, React JSMongoDB, MySQL"/>
            </div>
          </div>

          <div className="container mt-5" id="advisors">
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-12 mx-auto text-centerX" data-aos="fade-up" data-aos-duration="1200">
                <h3 className="display-4 font-weight-bold mb-5">
                  Advisory Board
                </h3>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="600">
                  <div className="card-body">
                    <img src={require('../img/team/gareth-owenson.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Dr Gareth Owenson</h4>
                    <h5>Technical Advisor</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#gareth">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="gareth" img={require('../img/team/gareth-owenson.jpg')} name="Dr Gareth Owenson" title="Technical Advisor" linkedin="" description="Gareth holds a PhD in Computer Science and a BSc in Internet Technology and is a specialist in internet security, cryptography and distributed systems. He has conducted research in large distributed systems with a particular interest in cryptographic applications such as darknets and digital forensics, including developing automated analysis techniques to rapidly reverse engineer advanced malware to determine its functionality and develop countermeasures. He has authored many publications in journals and conferences and regularly serves as a referee for Elsevier, IEEE and CHINACOM. He has conducted a large study into the use of darknets; he is widely recognised as an expert in this field and frequently speaks on this topic. He has also advised the UK and US governments on darknets and internet filtering policy."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <img src={require('../img/team/bo-lasater.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/bolasater">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Bo Lasater</h4>
                    <h5>Senior Product Advisor</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#bo">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="bo" img={require('../img/team/bo-lasater.jpg')} name="Bo Lasater" title="Senior Product Advisor" linkedin="bolasater" description="Bo's 20+ year career in Silicon Valley has focused on product design and engineering.  He started as an engineering manager at Morgan Interactive, one of the early leaders in multimedia entertainment, and eventually became a designer and producer. In the late 90s and early 2000s, he co-founded two successful sister companies, Fort Point Partners and Totality.  Fort Point built high-performance e-commerce sites and Totality provided systems integration and application management services for them. Totality raised over $100m and was eventually sold to Verizon.  Since then, Bo founded SuperEgo Games, a company that developed console episodic content for Sony's PlayStation network. He also ran the Reputation project at MySpace. Currently, Bo heads engineering and product at Mido Play, a venture he co-founded that is building a social-mobile platform for lottery applications."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1400">
                  <div className="card-body">
                    <img src={require('../img/team/johannes-frohling.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/johannes-froehling-8a7a3065">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Johannes Fröhling</h4>
                    <h5>Technology Advisor</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#johannes">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="johannes" img={require('../img/team/johannes-frohling.jpg')} name="Johannes Fröhling" title="Technology Advisor" linkedin="johannes-froehling-8a7a3065" description="After spending his whole career working in the strategy departments of public high-tech companies – mainly in the semiconductor and display sectors – he founded his own investment company running merger and acquisition projects with a strong focus on deeptech. He works with leading venture capital companies, private equity firms and private investors on a global basis. Johannes can rely on his global experience and network which he has generated working for over three years in China at Grace Semiconductor and a further three years in the USA at Applied Materials. Beyond that he lived and worked in India and Japan for several years. Johannes holds an MBA and a Graduate Engineering degree in semiconductor technology."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <img src={require('../img/team/rupert-boswall.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Rupert Boswall, RPC</h4>
                    <h5>Legal Advisor</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#rupert">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="rupert" img={require('../img/team/rupert-boswall.jpg')} name="Rupert Boswall" title="Legal Advisor"  linkedin="" description="Rupert is RPC's senior partner, a non-executive chairman role. Rupert acts on high-value international disputes and investigations in the financial, technology, infrastructure, natural resources, offshore and wealth sectors.  He is noted for his lateral thinking and strategic leadership of teams on cross-border projects.  Although a litigator by background, he is a solver of clients' issues in deal making as well as disputes. Rupert has particular experience acting for clients from Africa, Russia, the CIS, the Middle East, the PRC and SE Asia. He is dual-qualified in England & Wales and Hong Kong."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <img src={require('../img/team/patrick-ormond.jpg')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/patrick-ormond-3567a797">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Patrick Ormond, Walkers</h4>
                    <h5>Legal Advisor</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#patrick">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="patrick" img={require('../img/team/patrick-ormond.jpg')} name="Patrick Ormond, Walkers" title="Legal Advisor" linkedin="patrick-ormond-3567a797" description="Patrick advises financial institutions, corporates, funds and investors on international finance and corporate transactions. His finance experience includes advising both lenders and borrowers on a wide range of bilateral and syndicated transactions, including real estate finance, construction finance, project finance, structured finance, acquisition finance and general corporate financings. His corporate experience includes advising on mergers and acquisitions (including take-privates), joint ventures, IPOs and corporate reorganisations."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <img src={require('../img/team/nicola-mckilligan-regan.png')} alt=""/>
                    <a target="_blank" rel="noopener noreferrer" className="social-circle" title="Linkedin" href="https://www.linkedin.com/in/nicolaregan1">
                      <i className="fab fa-linkedin-in"/>
                    </a>
                    <h4>Nicola McKilligan-Regan</h4>
                    <h5>Data Privacy and GDPR Advisor</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#nicolamc">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <TeamModal id="nicolamc" img={require('../img/team/nicola-mckilligan-regan.png')} name="Nicola McKilligan-Regan" title="Data Privacy and GDPR Advisor" linkedin="nicolaregan1" description="Senior Partner at Privacy Partnership and CEO Founder of SmartPrivacy. Advisor on all aspects of data privacy law including the GDPR, cybernetics and data ethics. She is a human rights and data privacy law specialist with over 20 years’ experience with multinationals and with the Information Commissioner's Office."/>

            </div>
          </div>

          <div className="container mt-5" id="partners">
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-12 mx-auto" data-aos="fade-up" data-aos-duration="1200">
                <h3 className="display-4 font-weight-bold mb-5">Partners</h3>
              </div>
            </div>
          </div>

          <div className="container partners-container">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="600">
                  <div className="card-body">
                    <div className="card-image">
                      <img src={require('../img/partners/partner-rpc.png')} alt=""/>
                    </div>
                    <h4>RPC</h4>
                    <h5>English Law Legal Advice</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#rpc">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <PartnerModal id="rpc" img={require('../img/partners/partner-rpc.png')} name="RPC" title="English Law Legal Advice"  linkedin="" description="RPC (www.rpc.co.uk) is a major city law firm with headquarters in London and offices in Bristol, Singapore and Hong Kong. RPC has significant expertise in technology, IP, investment structures, private equity, finance and tax, litigation, insurance and real estate and is ranked highly relative to peers in independent legal directories such as Chambers UK and The Legal 500. RPC have acted for coin issuers, crypto-exchanges, blockchain developers and blockchain start-ups, and their experience includes advising on the intellectual property aspects of blockchain, IP licencing arrangements, advising on ICO fundraising and structuring, platform terms and conditions, the settlement of claims arising from hacks of major cryptocurrency exchanges, advising on the theft of bitcoin sale proceeds, including coin provenance / tracing issues, and advising cryptocurrency-related businesses and token issuers on regulatory, licensing and commercial issues."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1400">
                  <div className="card-body">
                    <div className="card-image">
                      <img src={require('../img/partners/partner-shuftipro.png')} alt=""/>
                    </div>
                    <h4>Shufti Pro</h4>
                    <h5>Identity Verification / KYC Provider</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#shuftipro">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <PartnerModal id="shuftipro" img={'../img/partners/partner-shuftipro.png'} name="Shufti Pro" title="Identity Verification / KYC Provider"  linkedin="" description="Shufti Pro provides next generation end-to-end identity verification services. It is a SaaS product offering KYC and AML verification as its basic features of operation. The hybrid artificial and human intelligence technology of Shufti Pro has the ability to verify 7 billion people living on planet Earth with 99.6% precision. Shufti Pro has engaged customers from various countries and belonging to diverse categories. Its digital KYC services have enabled its clients to identify their customers with frictionless accuracy, helping them to reduce cost overheads. Shufti Pro helps its banking and finance customers by providing AML Compliance services through a gigantic data bank that is updated every 14 minutes."/>


              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <div className="card-image">
                      <img src={require('../img/partners/partner-walkers.jpg')} alt=""/>
                    </div>
                    <h4>Walkers</h4>
                    <h5>British Virgin Islands law legal advice</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#walkers">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <PartnerModal id="walkers" img={require('../img/partners/partner-walkers.jpg')} name="Walkers" title="British Virgin Islands Law Legal Advice"  linkedin="" description="Walkers (www.walkersglobal.com) is a leading international law firm that provides legal, corporate and fiduciary services to global corporations, financial institutions, capital markets participants and investment fund managers. Walkers is consistently ranked in the top tier of the leading global legal directories. Walkers has created an offshore market leading FinTech team and works closely with policymakers, regulators and governments to facilitate appropriate legislation and regulation that keeps pace with innovation. Walkers has particular expertise of advising businesses specialising in blockchain, digital assets, alternative model finance and a wide range of related activities and services including funds, issuers, custodians and broker dealers."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <div className="card-image">
                      <img src={require('../img/partners/partner-harvex.png')} alt=""/>
                    </div>
                    <h4>Harvex</h4>
                    <h5>Crypto Accounting & Structuring</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#harvex">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <PartnerModal id="harvex" img={require('../img/partners/partner-harvex.png')} name="Harvex" title="Crypto Accounting & Structuring"  linkedin="" description="Harvex are a specialist international Accounting, Tax & Advisory firm operating in the rapid growth sectors of Cryptocurrency, Blockchain and Online Gaming. From their offices in UK and Malta, Harvex have been at the forefront of Cryptocurrency / ICO development providing services such as International Setup & Structuring, Banking, Accounting and Tax.  As highly experienced Cryptocurrency Accountants & Advisors, Harvex have extensive and in-depth connections and knowledge in all the major worldwide crypto hotspots. Harvex have supported both corporate and individual clients, adapting to ever evolving legislation and requirements.  Within the cryptocurrency sector, Harvex’ clients include Crypto-Exchanges, Trading & Investment Firms, Asset Management Funds and Brokerages. Additionally, Harvex have worked with multiple Initial Coin Offerings, launching from a variety of jurisdictions. Harvex’ clients extend across the Online Gaming world, from multiple Casinos & Poker Sites to Crypto Payment Processors."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <div className="card-image">
                      <img src={require('../img/partners/partner-bsi.png')} alt=""/>
                    </div>
                    <h4>BSI</h4>
                    <h5>Pen Testing & 3rd-Party Security</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#bsi">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <PartnerModal id="bsi" img={require('../img/partners/partner-bsi.png')} name="BSI" title="Pen Testing & 3rd-Party Security"  linkedin="" description="BSI Cybersecurity and Information Resilience (UK) Ltd is an NCSC approved company that provides penetration testing of IT systems to identify potential vulnerabilities and recommend effective security countermeasures.  A service provider can analyse the systems or networks that companies rely on to carry out their business securely and effectively by conducting a number of tests designed to identify any weaknesses using publicly known vulnerabilities and common configuration faults.  BSI’s clients include government and critical national infrastructure (CNI) customers, demonstrating that they have the highest possible standards.  BSI is also a Certified Cyber-Security Consultancy (CCSC) and we can confirm that they met our high standards for security architecture, risk assessment and risk management services.  The Certified Cyber-Security Consultancy (CCSC) scheme was developed to enable easy identification of companies capable of offering high quality, tailored, expert cybersecurity advice. Developed originally by CESG, the scheme is now operated by the National Cyber Security Centre (NCSC) which is part of GCHQ (Government Communications Headquarters)."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <div className="card-image">
                      <img src={require('../img/partners/partner-liquid.png')} alt=""/>
                    </div>
                    <h4>Liquid</h4>
                    <h5>Exchange Platform</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#liquid">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <PartnerModal id="liquid" img={require('../img/partners/partner-liquid.png')} name="Liquid" title="Exchange Platform" description="Founded in 2014, Liquid.com (“Liquid”) describes itself as a global cryptocurrency platform that provides trading, exchange, and blockchain-based financial services. With offices in Japan, Singapore, Vietnam, and the USA, Liquid aims to combine a network of local partners with extensive team experience in banking and financial products to deliver best-in-class financial services for its customers. According to the team, Liquid is the world’s largest crypto-fiat platform by transaction volume that is regulated in Japan. Powered by the World Book, which provides customers enhanced price matching and deeper liquidity for various fiat and cryptocurrency pairs, Liquid offers trading services for major cryptocurrencies against fiat currency pairs (e.g. JPY, USD, SGD, AUD, EUR)."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <div className="card-image">
                      <img src={require('../img/partners/partner-coinall-circle.png')} alt=""/>
                    </div>
                    <h4>Coinall</h4>
                    <h5>Exchange Platform</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#coinall">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <PartnerModal id="coinall" img={require('../img/partners/partner-coinall-circle.png')} name="Coinall" title="Exchange Platform" description="CoinAll is a cryptoasset exchange located in Hong Kong. Their volume over the last 24 hours is $70.22M. They have 155 markets, with the most popular markets (trading pairs) being BTC/USDT, BTC/USDK, and LTC/BTC. The exchange is rated “?” which means “Uncategorized.” Out of 160 exchanges, they are ranked #156 by transparency and volume. CoinAll accounts for <1% total volume in the cryptosphere indexed by us. They are a crypto-only exchange."/>

              <div className="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
                <div className="card" data-aos="fade-left" data-aos-duration="1000">
                  <div className="card-body">
                    <div className="card-image">
                      <img src={require('../img/partners/partner-transform.png')} alt=""/>
                    </div>
                    <h4>Transform Group</h4>
                    <h5>Blockchain Communications</h5>
                    <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#transformgroup">
                      View
                    </div>
                  </div>
                </div>
              </div>
              <PartnerModal id="transformgroup" img={require('../img/partners/partner-transform.png')} name="Transform Group" title="Blockchain Communications" description="Transform Group is the world’s leading blockchain public relations, advisory, events, and social media company, with offices in New York, Los Angeles, San Francisco, Las Vegas, Toronto, and global headquarters in San Juan, Puerto Rico. Transform Group is dedicated to creating and sustaining industry leadership positions for its clients through superior strategy, relationships, market intelligence and consistent execution."/>

            </div>
          </div>
        </section>
      </div>
    </div>
  </Layout>
);

export default Team;
