import React from 'react';

const PartnerModal = ( props ) => (
  <div className="partner-modal modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
    <div className="modal-dialog modal-dialog-zoom modal-md">
      <div className="modal-content">
        <div className="modal-body p-lg-5">
          <div className="row flex-center">
            <div className="col col-4 col-sm-4 col-md-4 mx-auto">
              <img src={props.img} alt={props.name} className="mb-0" />
            </div>
            <div className="col col-8 col-sm-8 col-md-8 mx-auto">
              <h4 className="display-4 font-weight-bold mt-3">{props.name}</h4>
              <h5 className="mb-4">{props.title}</h5>
            </div>
          </div>

          <div className="row">
            <div className="col col-12 col-sm-12 col-md-12 mx-auto">
              <hr className="my-5"/>
              <p>{props.description}</p>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
);

export default PartnerModal;
